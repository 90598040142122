.header {
  width: 100%;
  display: flex;
  align-items: center;
  height: 76px;
  position: fixed;
  background-color: rgb(0,0,25);
  z-index: 100;
  top: 0;
  padding: 0;
  left: 0;
}
.brand {
  display:flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    margin-top: 0px;
    margin-right:20px;
    border-right: 1px solid grey;
}
.brand img {
  width: 80%;
  margin: 0 0 0 10px;
  height: 38px;
}
.jd {
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
}

.links {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 15px;
}
.upload {
  font-size: 1rem;
  margin-left: 15px;
}
.btncss {
  margin: 0px 0px 0px 15px;
  color: black;
  border: none;
  /*font-size: 1rem;*/
  /*background: rgb(13, 110, 253);
  border-radius: 4px;*/
}
.company {
  font-size: 0.9rem;
  font-weight: bold;
  margin-left: 15px;
  color: #000;
  width: 300px;
  overflow: hidden;
}
.company div {
  display: flex;
}
.company div div:last-child{
  white-space: nowrap;
}
.logout {
  position: absolute;
  right: 0px;
  margin-right: 0px;
}
.logout button {
  background-color: rgb(0,0,25);
  color:white;
  /* margin-top: 5px; */
  /*height: 76px;*/
  margin-right: 25px;
  font-family: 'Inter';
  font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 100%;
}

.profile-avatar-container {
  position: fixed;
  top: 0;
  right: 0;
  display: block;
  padding: 10px;
}

.profile-avatar {
  position: fixed;
  top: 0;
  right: 0;
  display: block;
  cursor: pointer;
  /* Add other avatar styles as needed */
}

.dropdown {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1; /* Ensure the dropdown is on top of other content */ /* Add margin-top to separate it from the avatar */
  display: block; /* Make it a block element to appear below the avatar */
}

.dropdown ul {
  list-style: none;
  padding: 0;
}

.dropdown li {
  cursor: pointer;
}

/* Add more styles as needed for your design */
