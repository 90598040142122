/* styles.css */
.autocomplete-custom-height {
    .MuiAutocomplete-inputRoot {
      width:25rem; /* Adjust the height as needed */
    }
  
    .MuiAutocomplete-tag {
      height: 24px; /* Adjust the height of the chips as needed */ /* Add spacing between chips */
    }
  
    .MuiAutocomplete-popupIndicator {
      padding: 6px; /* Adjust padding around the dropdown indicator */
    }
  }
  