footer {
    position: fixed;
    background-color: gray;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 4px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    z-index: 9;
  }
  
  footer span{
    padding-right: 30px;
  }
  footer a{
    text-decoration: none;
    color:black
  }