.background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .3);; 
    position: fixed;
    top: 0;
    display: block;
    justify-content: center;
    align-items: center;
    z-index:99999999999999999999;
    /* margin-left: 0;  */
  }
  
  .container-jd {
    margin-top:150px;
    width: 500px;
    height: 300px;
    border-radius: 12px;
    background-color: rgb(23, 229, 195);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-left: 80px;
  }
  

  .titleCloseBtn {
    display: flex;
    /* justify-content: flex-end; */
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    cursor: pointer;
    display:inline-block;
    border:1px solid gray;
    margin: 2px;
  }
  
  
  .container-jd .body {
    display: block;
  }
  
  .container-jd .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container-jd .footer button {
    width: 150px;
    height: 45px;
    margin-right: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }