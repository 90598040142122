
.search-text-container {
    margin-top: 80px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

button.reject-button {
    border-color: rgba(246, 130, 130, 1);
    background: rgba(246, 130, 130, 1);
    padding: 3%;
    border-width: 5px;
    border-radius: 10px;
    text-wrap: nowrap;
}

button.submit-button {
    border-color: rgba(23, 229, 195, 1);
    background: rgba(23, 229, 195, 1);
    padding: 3%;
    border-width: 5px;
    border-radius: 10px;
    text-wrap: nowrap;
}
button.submit-position{
    margin: 10%;
}

textarea.large-text-input {
    border-color: rgba(23, 229, 195, 1);
    border-radius: 5px;
    margin-top: 20%;
}

.button-layer {
    display: flex;
}