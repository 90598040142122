.container {
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  height: 70px;
  margin: 0 auto;
  background-color: #17E5C3;
}

.navbar {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.navbar-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin: 0;
}

.navbar-item {
  margin-right: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 15px;
  font-family: Inter;
}

.navbar-item:hover {
  background-color: #0d6efd;
}

.navbar-item.active {
  font-weight: bold;
  border-bottom: #0d6efd;
}
