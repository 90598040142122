.thread-column {
    display: flex;
    flex-direction: column-reverse; /* Reverse to show the most recent message at the top */
  }
  
  .message-row {
    display: flex;
    align-items: flex-start; /* Align user image and message content vertically */
    margin-bottom: 10px;
    border-bottom: 1px solid grey;
  }
  
  .user-image img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .message-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .timestamp {
    font-size: 12px;
    color: gray;
    margin-left: 10px;
  }
  
  .message-text {
    margin-left: 10px;
    border-radius: 8px;
    width:100%;
  }

  .white-icon {
    color: grey;
  }

  .comment-header {
    display: flex;
    flex-direction: row;
}