.switch{
    position : relative;
    display: inline-block;
    width:60px;
    height: 26px;
}
.switch input{
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position : absolute;
    cursor : pointer;

    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #ccc
}
.slider::before {
    position : absolute;
    content: "";
    height: 20px;
    width: 26px;
    left:4px;
    bottom:4px;
    background-color: white;
    transition: 0.4s;

}

input:checked + .slider::before{
    transform : translateX(26px)
}
input:checked + .slider{
    background-color: #2196f3
}
.slider.rounded {
    border-radius : 24px;

}
.slider.rounded::before{
    border-radius : 50%;
}