.modall {
    position: fixed;
    inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 999;
    padding: 40px 20px 20px;
  }
  
  .modall-container {
    position: relative;
    width: auto;
    height: auto;
    background-color: white;
    border-radius: 10px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    padding: 15px;
  }

  .close-btn{
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    font-weight: bold;
    width: 30px;
    height: 30px;
  }

  .modall-header{
    border-bottom: 1px solid gray;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .modall-body-row{
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
  }

  .modall-body-column{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0.6rem;
  }

  .modall-body label {
    font-weight: bold;
    white-space: nowrap;
  }

  .profile-detail-section .form-control{
    padding: 0;
  }

  .modall-body table, 
  .modall-body th,
  .modall-body td {
    border-collapse: collapse;
    border: 1px solid;
    padding: 4px;
  }

  .modall-footer{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .rs-picker-menu {
    z-index: 9999 !important;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border-bottom: 1px solid #ccc;
  margin: 5px;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: #f1f1f1;
  float: left;
  border-top: 1px solid #ccc;
  outline: none;
  cursor: pointer;
  padding: 8px 10px;
  transition: 0.3s;
  font-size: 17px;
  margin-right: 5px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 0 6px;
  border: 1px solid #ccc;
  border-top: none;
}