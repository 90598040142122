
.resume-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    top: 76px;
    position: relative;
    /* flex: auto; */
}

.left-resume-container {
    width: 50%;
}

.right-resume-container {
    width: 50%;
    padding-top: 10%;
    padding-left: 5%;
}